import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7fa0d12d = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _880abe36 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _3a93e9f0 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _10543e16 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _2fb17ab6 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _7605fc00 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _360aca84 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _59fa47ce = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _69d4c9e2 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _3a3f88fa = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _730f0bd2 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _0ccf2f59 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _02b8e694 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _c13152a4 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _4c2879ce = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _64f6a7c0 = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _3a0ee7f6 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _0b977cb6 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _0910ddf6 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _0e1fcea0 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _7856cc3c = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _f4842020 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _6fe8487d = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _4ed5e603 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _0ba6ef02 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _37095ffe = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _55051ddc = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _60f5708c = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _0be3327a = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _c5bae82c = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _1071291f = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _72a48e57 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _f7eb034e = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _b1c7302a = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _a506fb74 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _6d98a4f8 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _ffc61074 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _0fb2876a = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _5ab2b52c = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _58761776 = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _2918da89 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _084a02c4 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _aa3fb072 = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _6212e5da = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _0e8eaa86 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _e47991c2 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _4ff38596 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _3db38a7c = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _1c165644 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _df1343ca = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _5523888d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _38f015c0 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _668d66b6 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _5cb63c9e = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _0dd8ec81 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _3cdb9184 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _65d11e2f = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _3351c913 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _0ccb49e6 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _53b63172 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _39e03a7f = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _3e9b43a4 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _499b3ad5 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _7cdb2f70 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _5b6ded92 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _3faab29e = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _166fdcca = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _1587d382 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _65277bcf = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _6362c3e3 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _19296091 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _59a56cf2 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _ee3997fe = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _7fa0d12d,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _880abe36,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _3a93e9f0,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _10543e16,
    name: "calendar"
  }, {
    path: "/customer",
    component: _2fb17ab6,
    name: "customer"
  }, {
    path: "/details",
    component: _7605fc00,
    name: "details"
  }, {
    path: "/goals",
    component: _360aca84,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _59fa47ce,
    name: "maisoku"
  }, {
    path: "/media",
    component: _69d4c9e2,
    name: "media"
  }, {
    path: "/privacy",
    component: _3a3f88fa,
    name: "privacy"
  }, {
    path: "/project",
    component: _730f0bd2,
    name: "project"
  }, {
    path: "/response",
    component: _0ccf2f59,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _02b8e694,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _c13152a4,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _4c2879ce,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _64f6a7c0,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _3a0ee7f6,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _0b977cb6,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _0910ddf6,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _0e1fcea0,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _7856cc3c,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _f4842020,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _6fe8487d,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _4ed5e603,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _0ba6ef02,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _37095ffe,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _55051ddc,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _60f5708c,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _0be3327a,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _c5bae82c,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _1071291f,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _72a48e57,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _f7eb034e,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _b1c7302a,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _a506fb74,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _6d98a4f8,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _ffc61074,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _0fb2876a,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _5ab2b52c,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _58761776,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _2918da89,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _084a02c4,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _aa3fb072,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _6212e5da,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _0e8eaa86,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _e47991c2,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _4ff38596,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _3db38a7c,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _1c165644,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _df1343ca,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _5523888d,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _38f015c0,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _668d66b6,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _5cb63c9e,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _0dd8ec81,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _3cdb9184,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _65d11e2f,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _3351c913,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _0ccb49e6,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _53b63172,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _39e03a7f,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _3e9b43a4,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _499b3ad5,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _7cdb2f70,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _5b6ded92,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _3faab29e,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _166fdcca,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _1587d382,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _65277bcf,
    name: "property-property",
    children: [{
      path: "contact",
      component: _6362c3e3,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _19296091,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _59a56cf2,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _ee3997fe,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
